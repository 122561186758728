/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Header from './header';
import './layout.css';
import styled, { ThemeProvider } from 'styled-components';

const Main = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  max-width: 650px;
  margin: auto;
  padding: 0 20px;
  width: 100%;
`;

const Footer = styled.footer`
  background-color: var(--accent);
  margin-top: 5rem;
  padding: 1rem;
  width: 100%;

  & * {
    color: var(--text-contrast);
  }
`;

const LinkedIn = styled.p`
  max-width: 960px;
  margin: auto;
`;

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <ThemeProvider theme={{}}>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <Header siteTitle={data.site.siteMetadata.title} />
        <Main>{children}</Main>
        <Footer>
          <LinkedIn>
            Braindump of <a href="https://www.linkedin.com/in/sebastiendufourbeausejour/">Sébastien Dufour-Beauséjour</a>
          </LinkedIn>
        </Footer>
      </div>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
