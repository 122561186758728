import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';

const SiteTitle = styled.p`
  margin: 0;
  padding: 1rem 0;
  font-size: 2rem;
  font-weight: bold;

  & > a {
    color: var(--text-contrast);
    text-transform: lowercase;
    font-family: 'work sans', 'Helvetica Neue', sans-serif;
    text-decoration: none;
  }
`;

const Root = styled.header`
  margin-bottom: 1.45rem;
  background-color: var(--accent);

  ${down('sm')} {
    margin-bottom: 1rem;
  }
`;

const Header = ({ siteTitle }) => {
  return (
    <Root>
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0.7rem 0`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <SiteTitle>
          <Link to="/">{siteTitle}.</Link>
        </SiteTitle>
      </div>
    </Root>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
