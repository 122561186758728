import React from 'react';

import Layout from '../common/layout';
import { SEO } from '../common/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
  </Layout>
);

export default NotFoundPage;
